import { Link } from "gatsby"
import React from "react"
import { Tag } from "../../types/Post"
import "./index.scss"

interface Props {
  tags: { nodes?: Tag[] }
  className?: string
}

const Tags: React.FC<Props> = ({ tags, className }) => {
  if (tags?.nodes?.length == 0) {
    return null
  }

  return (
    <div className="tags">
      {tags?.nodes && (
        <div className="tag">
          {tags.nodes?.map((tag, i) => {
            return <Link to={tag.uri} key={tag.id}>{`#${tag.name}`}</Link>
          })}
        </div>
      )}
    </div>
  )
}

export default Tags
